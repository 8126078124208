<template>
  <div class="jommove">
    <watch-head :title="$t('jommove')"
                :img="require('@/assets/bracelet_img/61.png')" />
    <div class="main">
      <div class="main-box">
        <div>E-mail</div>
        <van-field v-model="fromData.email"
                   :placeholder="$t('请填写E-mail')"
                   type="email" />
      </div>

      <div class="main-box">
        <div>{{$t('验证码')}}</div>
        <van-field v-model="fromData.code"
                   center
                   clearable
                   :placeholder="$t('请填写短信验证码')">
          <template #button>
            <van-button size="small"
                        type="primary"
                        :loading="codeLoading"
                        @click="getCode">
              <div v-if="isCode">{{$t('发送验证码')}}</div>
              <van-count-down style="color: #fff;"
                              v-else
                              :time="time"
                              format="ss"
                              @finish="countFinish" />
            </van-button>
          </template>
        </van-field>
      </div>

      <div class="main-box">
        <div>{{$t('jommove上级邀请码')}}</div>
        <div style="font-size: 14px;">({{$t('如jommove已绑定上级，可不填')}})</div>
        <van-field v-model="fromData.invite"
                   :placeholder="$t('请填写jommove上级邀请码')"
                   type="text" />
      </div>

      <div class="main-box">
        <div>{{$t('昵称')}}</div>
        <van-field v-model="fromData.nickname"
                   :placeholder="$t('请输入昵称')"
                   type="text" />
      </div>

      <div class="main-box">
        <div>{{$t('密码')}}</div>
        <van-field v-model="fromData.password"
                   :placeholder="$t('请输入密码')"
                   type="password" />
      </div>
      <button class="min-but"
              @click="bind"
              :loading="bindLoading">{{$t('绑定')}}</button>
    </div>
    <watch-foot />
  </div>
</template>
   
   <script>
import watchHead from '@/components/watchHead/index.vue'
import watchFoot from '@/components/watchFoot/index.vue'
import { bindAppEmailApi, sendEmailApi } from '@/api/api'

export default {
  components: {
    watchHead,
    watchFoot,
  },
  data () {
    return {
      fromData: {
        email: '',
        code: '',
        invite: '',
        nickname: '',
      },
      time: 60 * 1000,
      isCode: true,
      codeLoading: false,
      bindLoading: false
    }
  },
  created () {

  },
  computed: {

  },
  methods: {
    // 发送验证码
    getCode () {
      this.codeLoading = true
      if (!this.isCode) {
        return this.$toast(this.$t('请倒计时结束后再次发送'));
      }
      if (!this.isValidEmail(this.fromData.email)) {
        return this.$toast(this.$t('请填写正确的邮箱'));
      }
      let data = {
        email: this.fromData.email
      }
      sendEmailApi(data).then(res => {
        this.$toast(res.msg);
        this.codeLoading = false

        if (res.code == 0) {
          this.isCode = false
        } else {
          this.isCode = true
        }
      }).catch(err => {
        this.$toast(err.msg);
        this.codeLoading = false
        this.isCode = true
      })
    },
    // 倒计时结束触发
    countFinish () {
      this.isCode = true
    },
    // 邮箱验证
    isValidEmail (email) {
      const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      return pattern.test(email);
    },
    // 提交
    bind () {
      if (!this.isValidEmail(this.fromData.email)) {
        return this.$toast(this.$t('请填写正确的邮箱'));
      }
      if(!this.fromData.code){
          return this.$toast(this.$t('请填写验证码'));
      }
      if(!this.fromData.password){
          return this.$toast(this.$t('请输入密码'));
      }
      this.bindLoading = true;
      bindAppEmailApi(this.fromData).then(res => {
        this.$toast(res.msg);
        this.bindLoading = false;
        if(res.code == 0){
          this.$router.go(-1)
        }
      }).catch(err => {
        this.$toast(err.msg);
        this.bindLoading = false;
      })
    }
  }
}
   </script>
   
   <style lang="less" scoped>
.jommove {
  width: 100%;
  min-height: 100vh;
  padding-top: 75px;
  padding-bottom: 60px;
  background-image: url(~@/assets/bracelet_img/8.png);
  background-repeat: repeat-y;
  background-size: 100% 100%;
  color: #ffffff;
  .main {
    margin-top: 27px;
    padding: 0 25px;

    .main-box {
      margin-top: 20px;
    }
    .min-but {
      width: 100%;
      margin-top: 40px;
      padding: 10px 0;
      background-color: #a7f264;
      border: 0;
      color: #2b2b2b;
    }
  }
}
</style>